import cn from "classnames";

import { ItemSetState, TransportRequest } from "@brenger/api-client";
import { isNowInDateTimePeriod } from "@brenger/utils";

export type ContactType = "pickup" | "delivery" | "customer";

/**
 * THIS IS THE "MASTER" SET OF AVAILABLE STATUSES
 * Rationale: the status is represented as a number for several reasons.
 * First, it makes progress comparisions easier via logical operators.
 * Second, it de-couples status names from the overall logic, which should make any new statuses or re-naming easier to accomodate.
 */
export type Status =
  // transport canceled
  | 0
  // transport submitted
  | 1
  // driver connected and ready for pickup
  | 2
  // driver is on the way (to pickup stop)
  | 3
  // items picked up (status tracking ends here for the pickup stop contact)
  | 4
  // items delivered (status journey ends here for delivery stop contact and customer unless dispute)
  | 5
  // transport is in dispute
  | 6;

// export function makeAccessControlList<T extends string>(
//   chart: Record<ContactType, Record<T, Status[] | undefined>>
// ): (contactType: ContactType, action: T, status: Status) => boolean {
//   return (contactType: ContactType, action: T, status: Status) => {
//     return !!chart[contactType][action]?.includes(status);
//   };
// }

interface Perms<T extends string> {
  /**
   * Does the user have some perms available in the provided chart?
   */
  hasSomePerms: boolean;
  /**
   * The perms table.
   */
  perms: Record<T, boolean> | undefined;
}

export function createAccessControl<T extends string>(
  chart: Record<ContactType, Record<T, Status[] | undefined>>
): (contactType: ContactType | undefined, status: Status | undefined) => Perms<T> {
  return (contactType: ContactType | undefined, status: Status | undefined) => {
    if (contactType === undefined || status === undefined) {
      return {
        hasSomePerms: false,
        perms: undefined,
      };
    }

    const actions = Object.keys(chart[contactType]) as T[];

    const perms = actions.reduce(
      (prev, curr) => {
        return {
          ...prev,
          [curr]: !!chart[contactType][curr]?.includes(status),
        };
      },
      {} as Record<T, boolean>
    );

    return {
      hasSomePerms: Object.values(perms).some(Boolean),
      perms,
    };
  };
}

const statusColorMap: { [key in Status]: string } = {
  0: cn("text-red-600"),
  1: cn("text-green-400"),
  2: cn("text-green-400"),
  3: cn("text-green-400"),
  4: cn("text-green-400"),
  5: cn("text-green-400"),
  // @TODO add orange to `@brenger/react
  6: cn("text-orange-400"),
};

export const getColorClassnamesForStatus = (status: Status | undefined): string => {
  const defaultColors = cn("text-gray-800");

  if (status === undefined) return defaultColors;

  return statusColorMap[status];
};

export const itemSetStatusMap: { [key in ItemSetState]: Status } = {
  ready_for_pickup: 2,
  pickup_confirmed_by_customer: 4,
  // NOTE: unlike with delivery, it is ok to move the status along when driver has confirmed the pickup.
  // This way the delivery contact is not dependent on the pickup contact.
  pickup_confirmed_by_driver: 4,
  picked_up: 4,
  delivery_confirmed_by_customer: 5,
  // NOTE: we DO transition status to 5 at this stage, as core does not allow both parties to confirm.
  // In the future, core will allow both parties to confirm, at which point we should change "delivery_confirmed_by_driver" to status 4.
  delivery_confirmed_by_driver: 5,
  delivered: 5,
  disputed: 6,
  cancelled: 0,
};

export const getStatus = (tr: TransportRequest | null): Status | undefined => {
  const trState = tr?.state;

  if (!trState) return undefined;

  if (trState === "cancelled") return 0;

  // NOTE: TR status of submitted is more precise than the item_set status, which is "ready_for_pickup" eagerly.
  // After the TR is published, the itemset status becomes more precise.
  if (trState === "submitted") return 1;

  // Active commitemnt means
  const activeCommitment = tr?.pickups[0].active_commitment;

  if (!activeCommitment) {
    return 1;
  }

  // Provide a safeguard with "ready_for_pickup" so we can always safely and reliably access the itemSetStatusMap
  const itemSetState = tr?.item_sets[0].state || "ready_for_pickup";

  // NOTE: this section addresses the period of time BEFORE the pickup has been confirmed by driver and/or customer.
  // Here, we want to signal that the transport is "underway".
  // This is done by measuring the current moment against the committed pickup DTP.
  if (itemSetState === "ready_for_pickup" && activeCommitment) {
    const isCommitmentToday = isNowInDateTimePeriod(activeCommitment.committed_datetime_period, 2);
    if (isCommitmentToday) {
      return 3;
    }
  }

  return itemSetStatusMap[itemSetState];
};
