import cn from "classnames";
import React from "react";
import { Route as ReactRouterRoute, Redirect, Switch } from "react-router-dom";

import { Page } from "../../components";
import { TransportProvider, useTransportContext, useTransportParams } from "../../hooks";
import { Routes } from "../../utils";
import { AppointmentDetails } from "./AppointmentDetails";

/**
 * NOTE: the below components all derive context from the TransportProvider.
 * See implementation below.
 */
import { AppointmentSummary } from "./AppointmentSummary";
import { BannerCO2 } from "./BannerCO2";
import { CancelCTA } from "./Cancel/CancelCTA";
import { DamageClaimForm } from "./DamageClaimForm";
import { Driver } from "./Driver";
import { Map } from "./Map";
import { MapContainer } from "./Map/MapContainer";
import { NotificationContactDetails } from "./NotificationContactDetails";
import { OrderOverview } from "./OrderOverview";
import { PaymentCancelledModal } from "./PaymentCancelledModal";
import { ProductPayment } from "./ProductPayment";
import { Status } from "./Status";
import { StatusOverview } from "./StatusOverview";
import { UpdateDTP } from "./UpdateDTP";
import { UpdateStopDetailsCTA } from "./UpdateStopDetailsCTA";
import { UpdateStopDetailsForm } from "./UpdateStopDetailsForm";
import { clarityTag } from "../../utils/clarity";

const TransportDetails: React.FC = () => {
  const context = useTransportContext();

  React.useEffect(() => {
    if (context.isFlexibleDates) clarityTag({ flex_dates: "true" });
  }, [context.isFlexibleDates]);

  return (
    <div data-clarity-unmask="true">
      <NotificationContactDetails />
      <Page>
        <Status />
        <MapContainer>
          <Map />
        </MapContainer>
        <Driver />
        <AppointmentSummary />
        <UpdateStopDetailsCTA />
        <UpdateDTP />
        <BannerCO2 />
        <div className={cn("xl:flex", "xl:gap-x-40", "xl:container", "xl:mx-auto", "xl:px-4")}>
          <OrderOverview />
          <AppointmentDetails />
        </div>
        <ProductPayment />
        <StatusOverview />
        <CancelCTA />
        <PaymentCancelledModal />
      </Page>
    </div>
  );
};

const TransportDetailsEdit: React.FC = () => {
  return (
    <Page data-clarity-unmask="true">
      <Status />
      <UpdateStopDetailsForm />
    </Page>
  );
};

const TransportDetailsDamageClaim: React.FC = () => {
  return (
    <Page data-clarity-unmask="true">
      <Status />
      <DamageClaimForm />
    </Page>
  );
};

const Transport: React.FC = () => {
  const params = useTransportParams();
  return (
    <TransportProvider>
      <Switch>
        <ReactRouterRoute exact path={Routes.TRANSPORT_DETAILS} component={TransportDetails} />
        <ReactRouterRoute exact path={Routes.TRANSPORT_DETAILS_EDIT} component={TransportDetailsEdit} />
        <ReactRouterRoute exact path={Routes.TRANSPORT_DETAILS_DAMAGE_CLAIM} component={TransportDetailsDamageClaim} />
        <Redirect to={`/${params.id}`} />
      </Switch>
    </TransportProvider>
  );
};

export default Transport;
