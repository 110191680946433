import { Address as IAddress, Contact, ItemSetAttributes, TransportRequest } from "@brenger/api-client";
import { Small, Strong } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import { Address, Card, DateTimePeriod, StatusIcon } from "../../components";
import { useTranslationContext } from "../../hooks";
import { getColorClassnamesForStatus, getStatus, getTypedStopsFromTr } from "../../utils";

interface Props {
  tr: TransportRequest;
}

export const CardForBusiness: React.FC<Props> = ({ tr }) => {
  const { t } = useTranslationContext();
  const { pickup, delivery } = getTypedStopsFromTr(tr);

  const deliveryAddress = delivery?.address as IAddress | undefined;
  const titles = tr.item_sets.map((is) => is.title);
  const orderNumbers = tr.item_sets.map((is) => is.client_reference).filter(Boolean);

  const status = getStatus(tr);

  const pickupContact = pickup.contact as Contact;
  const pickupContactName = `${pickupContact.first_name} ${pickupContact.last_name}`;

  const deliveryContact = delivery.contact as Contact;
  const deliveryContactName = `${deliveryContact.first_name} ${deliveryContact.last_name}`;

  const isReturnOrder = tr.item_sets.some(
    (is) => (is.attributes as ItemSetAttributes)?.business_order_type === "return"
  );

  const contactName = isReturnOrder ? pickupContactName : deliveryContactName;

  if (status === undefined) return null;

  return (
    <Link target="_blank" to={`/${getIdFromIri(tr["@id"])}`}>
      <Card
        header={
          <>
            <div className={cn("grid", "grid-cols-6", "gap-4")}>
              <div className={cn("col-span-5")}>
                <div className={cn("text-blue-600")}>
                  <Strong>{contactName}</Strong>
                </div>
              </div>
              <div className={cn("col-span-1", "flex", "justify-end")}>
                <StatusIcon
                  status={status}
                  contactType="customer"
                  className={cn("w-6", "h-6", getColorClassnamesForStatus(status))}
                />
              </div>
            </div>
            {/* NOTE: some business orders do not have titles */}
            {titles.length > 0 && <div>{titles.join(", ")}</div>}
          </>
        }
        body={
          <div className={cn("flex", "items-center", "justify-between")}>
            <Small>
              <Address address={deliveryAddress} />
            </Small>
            {/* ONLY SHOW THE PICKUP DTP WHEN AVAILABLE */}
            {pickup.active_commitment && (
              <Small>
                <DateTimePeriod className={cn("text-right")} dtp={pickup.active_commitment.committed_datetime_period} />
              </Small>
            )}
          </div>
        }
        footer={
          <>
            <Small className={cn("text-gray-700", "uppercase", "block")}>ID {tr.short_id}</Small>
            {orderNumbers.length > 0 && (
              <Small className={cn("text-gray-700", "block")}>
                {t((d) => d.order_number)}: {orderNumbers.join(" / ")}
              </Small>
            )}
          </>
        }
      />
    </Link>
  );
};
